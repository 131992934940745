import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import FacebookIcon from '@mui/icons-material/Facebook';
import './style.scss';
// import Loading from '../../components/loading/Loading';
// import LanguageIcon from '@mui/icons-material/Language';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import { useTranslation } from 'react-i18next';
// import { loginWithEmail, loginWithFacebook } from '../../api/apiLogin';
// import { postFanpage } from '../../api/apiFanpage';

const LoginForm = () => {
  // const { t, i18n } = useTranslation();
  // const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);
  // const [isLoadingBeforRender, setIsLoadingBeforeRender] = useState(true);
  // const [language, setLanguage] = useState('vi');
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');

  // useEffect(() => {
  //   const delayRendering = setTimeout(() => {
  //     setIsLoadingBeforeRender(false);
  //   }, 300);

  //   // Hủy timeout khi component unmount để tránh memory leak
  //   return () => clearTimeout(delayRendering);
  // }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem('accessToken');
  //   const mfm_token = localStorage.getItem('mfmToken');
  //   const isLoggedIn = token && mfm_token;
  //   // Nếu đã đăng nhập, chuyển hướng người dùng đến trang /home
  //   if (isLoggedIn) {
  //     navigate('/Information');
  //   }
  // }, [navigate]);
  //hàm cấp quyền FB
  // async function authLoginFacebook() {
  //   try {
  //     const response = await new Promise((resolve, reject) => {
  //       window.FB.login(
  //         function (response) {
  //           if (response) {
  //             resolve(response);
  //           } else {
  //             reject(null);
  //           }
  //         },
  //         {
  //           auth_type: "rerequest",
  //           scope: "email, public_profile, pages_show_list, business_management",
  //           enable_profile_selector: true,
  //           return_scopes: true,
  //         }
  //       );
  //     });
  //     return response;
  //   } catch (error) {
  //     console.error("Error in authLoginFacebook:", error);
  //     throw error;
  //   }
  // }

  //Hàm lấy thông tin user
  // async function getMeFacebook() {
  //   try {
  //     const response = await new Promise((resolve, reject) => {
  //       window.FB.api(
  //         "/me",
  //         {
  //           fields: "id,email,first_name,last_name,name,picture.width(50).height(50)",
  //         },
  //         function (response) {
  //           if (response) {
  //             resolve(response);
  //           } else {
  //             reject(null);
  //           }
  //         }
  //       );
  //     });
  //     return response;
  //   } catch (error) {
  //     console.error("Error in getMeFacebook:", error);
  //     throw error;
  //   }
  // }

  //Hàm check kết nối fb
  // async function getLoginStatus() {
  //   try {
  //     return new Promise((resolve, reject) => {
  //       window.FB.getLoginStatus(function (response) {
  //         resolve(response);
  //       });
  //     });
  //   } catch (error) {
  //     console.error("Error in getLoginStatus:", error);
  //     throw error;
  //   }
  // }

  //hàm check login bằng fb với database
  // async function checkLoginWithFacebook(users) {
  //   try {
  //     const response = await loginWithFacebook(users[0]);
  //     return response;
  //   } catch (error) {
  //     console.error("Error in checkLoginWithFacebook:", error);
  //     throw error;
  //   }
  // }

  //hàm Login bằng fb
  // async function loginByFacebook() {
  //   setIsLoading(true);
  //   let users = []
  //   try {
  //     let response = await getLoginStatus();
  //     if (response.status !== "connected") {
  //       response = await authLoginFacebook()
  //       if (response.status !== "connected") {
  //         // handle can't connect server
  //         return
  //       }
  //     }
  //     const user = await getMeFacebook();
  //     const accessToken = response.authResponse.accessToken;
  //     users.push({
  //       fb_id: user.id,
  //       name: user.name,
  //       email: user.email,
  //       avatar: user.picture.data.url,
  //       access_token_fb: accessToken,
  //       language: language
  //     })
  //     const checkLogin = await checkLoginWithFacebook(users)
  //     localStorage.setItem('mfmUserID', checkLogin.id)
  //     localStorage.setItem('accessToken', checkLogin.access_token_fb)
  //     localStorage.setItem('mfmToken', checkLogin.token_mfm)
  //     const payloadFanpage = await getFanpageFacebook();
  //     postFanpage(checkLogin.token_mfm, { "fanPages": payloadFanpage });
  //     navigate('/Information');
  //     setIsLoading(false);
  //   } catch (error) {
  //     // hiển thị popup báo lỗi đăng nhập
  //     console.error("Error in loginByFacebook:", error);
  //   }
  // }

  // //hàm Login bằng email
  // async function loginByEmail() {
  //   setIsLoading(true);
  //   let loginForm = {
  //     email: email,
  //     password: password,
  //     language: language
  //   }
  //   try {
  //     const response = await loginWithEmail(loginForm);
  //     if (!response.error) {
  //       localStorage.setItem('mfmUserID', response.id)
  //       localStorage.setItem('accessToken', response.access_token_fb);
  //       localStorage.setItem('mfmToken', response.token_mfm)
  //       navigate('/Information');
  //       setIsLoading(false);
  //     } else {
  //       console.log("email hoặc mật khẩu không đúng");
  //       // hiển thị popup báo lỗi đăng nhập
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     // hiển thị popup báo lỗi đăng nhập
  //     console.error("Error in loginByEmail:", error);
  //     setIsLoading(false);
  //   }

  // }

  // //hàm chọn ngôn ngữ
  // const handleChangeLanguage = (event) => {
  //   i18n.changeLanguage(event.target.value);
  //   setLanguage(event.target.value);
  // };

  //hàm lấy fanpage từ Facebook
  // const getFanpageFacebook = async () => {
  //   if (window.FB) {
  //     return new Promise((resolve, reject) => {
  //       window.FB.api(
  //         '/me/accounts?fields=id,name,picture',
  //         'GET',
  //         {},
  //         async function (response) {
  //           const fanPagesToFb = response.data.map(fanpage => ({
  //             id: fanpage.id,
  //             name: fanpage.name,
  //             url: fanpage.picture.data.url
  //           }));
  //           resolve(fanPagesToFb);
  //         }
  //       );
  //     });
  //   } else {
  //     console.error("Facebook SDK is not loaded. Logout and login again! If want update Fanpage on Facebook");
  //   }
  // }


  return (
    <>
      <div className="Login_changePage">
        <div className="Login_changePage_box">
            <div className="Login_changePage_box_title">Chân thành cảm ơn quý khách đã ủng hộ và sử dụng app của chúng tôi thời gian qua</div>
            <div>Để nâng cao chất lượng dịch vụ và phát hành những tính năng mới trong thời gian sắp tới</div>
            <div>chúng tôi dời địa chỉ domain về <a style={{color: 'red'}} href="https://www.megamess.info.vn/vi">www.megamess.info.vn</a></div>
            <div>
              Quý khách đăng nhập, sử dụng sẽ có trải nghiệm tốt hơn và chờ những tính năng mới phát hành free nhé!!!
            </div>
            <div className="Login_changePage_box_action">
              <a className="Login_changePage_box_action_btn1" href="https://www.megamess.info.vn/vi">
                Chuyển trang ngay
              </a>
              <a className="Login_changePage_box_action_btn2" href="https://www.fshare.vn/file/PNU3KBKM7UFA">
                Download extension mới
              </a>
            </div>
        </div>
      </div>
      {/* <div className='Login'>
        {isLoadingBeforRender ? (<Loading />) : (
          <div className='Login_container'>
            <div className='Login_box'>
              <form className='Login_form'>
                <div className='Login_form_logo'>
                  <img width={200} src="/image/Megamess.svg" alt="logo" />
                </div>
                <span className='Login_form_title'>{t('Login.Login')}</span>
                <div className='Login_btn_login' onClick={() => loginByFacebook()}>
                  <FacebookIcon className='Login_btn_icon' />
                  <span>Facebook</span>
                </div>
                <div className='Login_or'>
                  <div>{t('Login.Or')}</div>
                  <div>{t('Login.ByEmail')}</div>
                </div>
                <div className='Login_box_title'>
                  Email
                </div>
                <div className='Login_box_input'>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className='Login_box_title'>
                  {t('Login.Password')}
                </div>
                <div className='Login_box_input'>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className='Login_box_fogot'>
                  <span>{t('Login.ForgotPassword')}</span>
                </div>
                <div className='Login_btn_login'
                  onClick={() => loginByEmail()}
                >
                  {t('Login.Login')}
                </div>
                <div className='Login_language'>
                  <div className='Login_language_title'>
                    <LanguageIcon className='Login_language_icon' />
                  </div>
                  <div className='Login_language_list'>
                    <Select
                      className='Login_language_select'
                      value={language}
                      onChange={handleChangeLanguage}
                    >
                      <MenuItem value="vi">
                        <img src="image/vn.png" alt="logo" />
                        <span style={{ marginLeft: "5px" }}>Việt Nam</span>
                      </MenuItem>
                      <MenuItem value="en">
                        <img src="image/usa.png" alt="logo" />
                        <span style={{ marginLeft: "5px" }}>English</span>
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </form>
              <div className='Login_footer'>
                <div className='Login_footer_item'>
                  <a href="/ServicePolicy" target="_blank" rel="noopener noreferrer">
                    Chính sách dịch vụ
                  </a>
                </div>
                <div className='Login_footer_item'>
                  <a href="/PrivacyTerms" target="_blank" rel="noopener noreferrer">
                    Điều khoản bảo mật
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoading && <Loading />}
      </div> */}
    </>
  );
};

export default LoginForm;
