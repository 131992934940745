import React from "react";
import './BettingPackage.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const BettingPackage = () => {
    const navigate = useNavigate();

    const handleClick = (packageName, price) => {
        navigate('/DetailPackage', { state: { packageName, price } });
    };

    return (
        <div className="BettingPackage">
            <div className="BettingPackage_bg"></div>
            <div className="BettingPackage_content">
                <div className="BettingPackage_content_header">
                    <div className="BettingPackage_content_header_title">
                        <h3>Gói dịch vụ</h3>
                    </div>
                </div>
                <div className="BettingPackage_content_body">
                    <div className="BettingPackage_content_body_item">
                        <div
                            className="BettingPackage_content_body_item_title"
                        >
                            Gói 1 tháng
                        </div>
                        <div className="BettingPackage_content_body_item_price_1">
                            299.000đ
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Phù hợp trải nghiệm
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Không giới hạn Fanpage
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Không giới hạn tin nhắn
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Hỗ trợ sớm trong vòng 24h
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_btn" onClick={() => handleClick('Gói 1 tháng', 299000)}>
                            <div>
                                Mua ngay
                            </div>
                        </div>
                    </div>
                    <div className="BettingPackage_content_body_item">
                        <div
                            className="BettingPackage_content_body_item_title"
                        >
                            Gói 3 tháng
                        </div>
                        <div className="BettingPackage_content_body_item_price_2">
                            789.000đ
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Tiết kiệm chi phí
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Không giới hạn Fanpage
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Không giới hạn tin nhắn
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Hỗ trợ sớm trong vòng 24h
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Nhiều ưu đãi cho lần gia hạn tiếp
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_btn" onClick={() => handleClick('Gói 3 tháng', 789000)}>
                            <div>
                                Mua ngay
                            </div>
                        </div>
                    </div>
                    <div className="BettingPackage_content_body_item">
                        <div 
                            className="BettingPackage_content_body_item_title"
                        >
                            Gói vĩnh viễn
                        </div>
                        <div className="BettingPackage_content_body_item_price_3">
                            4.799.000đ
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Siêu tiết kiệm
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Không giới hạn Fanpage
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Không giới hạn thời hạn dùng
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Có tính năng đặc biệt extension
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Ưu tiên cập nhật tính năng mới
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_description">
                            <CheckCircleIcon className="BettingPackage_content_body_item_description_icon" />
                            <div>
                                Ưu tiên hỗ trợ đặc biệt
                            </div>
                        </div>
                        <div className="BettingPackage_content_body_item_btn" onClick={() => handleClick('Gói vĩnh viễn', 4799000)}>
                            <div>
                                Mua ngay
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BettingPackage;