import React, { useEffect, useState } from "react";
import "./VerifyPhone.scss";
import firebase from "../../firebase";
import { postPhoneNumberUser } from "../../api/apiUsers";

const VerifyPhone = (props) => {
    const { userData, updateUserAfterPhoneVerification } = props;
    const token = localStorage.getItem('mfmToken');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [countdown, setCountdown] = useState(0);

    const setupReCaptcha = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'defaultCountry': 'VN'
            });
        }
    }

    useEffect(() => {
        setupReCaptcha();
    }, []);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [countdown]);

    const formatPhoneNumber = (phone) => {
        // Thêm mã quốc gia +84 vào số điện thoại nếu chưa có
        if (!phone.startsWith('+84')) {
            return '+84' + phone.slice(1);
        }
        return phone;
    }

    const updatePhoneNumber = async (token, phone, id_user) => {
        try {
            await postPhoneNumberUser(token, phone, id_user);
            console.log('Phone number updated successfully');
        } catch (error) {
            console.error('Error updating phone number:', error);
        }
    };

    const handSendOTP = async () => {
        const appVerifier = window.recaptchaVerifier;
        const formattedPhoneNumber = formatPhoneNumber(phoneNumber); // Định dạng số điện thoại
        try {
            const confirmationResult = await firebase.auth().signInWithPhoneNumber(formattedPhoneNumber, appVerifier);
            window.confirmationResult = confirmationResult;
            setShowOtpInput(true);
            setCountdown(120);
        } catch (error) {
            console.error(error);
            alert('Lỗi xác thực từ hệ thống Firebase Google. Bạn có thử lại sau ít phút. Hoặc liên hệ Fanpage: https://www.facebook.com/megamess.vn để chúng tôi xác thực giúp bạn'); // Thêm dòng này để hiển thị thông báo lỗi
        }
    }

    const handleVerifyOtp = async () => {
        const confirmationResult = window.confirmationResult;
        try {
            const result = await confirmationResult.confirm(otp);
            const user = result.user;
            await updatePhoneNumber(token, { phone_number: user.phoneNumber}, userData.id_user);
            updateUserAfterPhoneVerification();

        } catch (error) {
            console.error('Error verifying OTP', error);
            alert('mã xác thực không đúng'); // Thêm dòng này để hiển thị thông báo lỗi
        }
    }

    return (
        <div className="VerifyPhone">
            <div className="VerifyPhone_box">
                <div className="VerifyPhone_box_title">Xác thực điện thoại</div>
                {!showOtpInput &&
                    <div className="VerifyPhone_box_form">
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Nhập số điện thoại"
                            pattern="0[1-9][0-9]{8}"
                            required
                            value={phoneNumber}
                            onChange={e => setPhoneNumber(e.target.value)}
                        />
                        <button type="submit" onClick={handSendOTP}>Nhận mã OTP</button>
                    </div>
                }
                {showOtpInput && ( // Chỉ hiển thị ô nhập OTP khi showOtpInput là true
                    <div className="VerifyPhone_box_form">
                        <input
                            type="tel"
                            name="OTP"
                            placeholder="Nhập mã OTP"
                            value={otp}
                            onChange={e => setOtp(e.target.value)}
                        />
                        <button type="submit" onClick={handleVerifyOtp}>Xác thực OTP</button>
                        {countdown > 0 && (
                            <div className="VerifyPhone_box_form_countdown">
                                Nhận mã OTP sau: {countdown} giây
                            </div>
                        )}
                        {countdown === 0 && (
                            <div className="VerifyPhone_box_form_acction">
                                <div className="VerifyPhone_box_form_acction_resend" onClick={handSendOTP}>
                                    Lấy lại mã OTP
                                </div>
                                <div className="VerifyPhone_box_form_acction_change" onClick={() => setShowOtpInput(false)}>
                                    Đổi số điện thoại
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div id="recaptcha-container"></div>
        </div>
    )
}

export default VerifyPhone;
