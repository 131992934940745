import axiosClient from './axiosClient';

//hàm lấy thông tin user theo fb_id
export const getCurrentUser = async (token) => {
  try {
    const response = await axiosClient.get(`/api/users/me`, {
      headers: {
        'Content-Type': 'application/json', // Example header
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching user with user_id`, error);
    throw error;
  }
}


export const postPhoneNumberUser = async (token, data, idUser) => {
  try {
    const response = await axiosClient.post(`/api/user/phonenumber/${idUser}`, data, {
      headers: {
          'Content-Type': 'application/json', // Example header
          'Authorization': `Bearer ${token}`
      }
  });
    return response.data;
  } catch (error) {
    console.error(`Error fetching user with user_id`, error);
    throw error;
  }
}