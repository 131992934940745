import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { postPackage } from '../../api/apiPackage';
import { useNavigate } from 'react-router-dom';
import './DetailPackage.scss';

const DetailPackage = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { packageName, price } = location.state || {};
    const {user} = props;
    const idUser = localStorage.getItem('mfmUserID');
    const token = localStorage.getItem('mfmToken');
    const [paymentOption, setPaymentOption] = useState("transfer");
    const [showModalTransfer, setShowModalTransfer] = useState(false);

    const handPayment = async () => {
        if (paymentOption === "transfer") {
            const newDay = new Date();
            let packageApproved = 0;
            let oldEndDate = '';
    
            // Xác định packageApproved dựa trên packageName
            switch(packageName) {
                case "Gói 1 tháng":
                    packageApproved = 2;
                    break;
                case "Gói 3 tháng":
                    packageApproved = 3;
                    break;
                case "Gói vĩnh viễn":
                    packageApproved = 4;
                    break;
                default:
                    console.error("Tên gói không hợp lệ");
                    return; // Kết thúc hàm nếu packageName không hợp lệ
            }
    
            // Xác định oldEndDate dựa trên newDay và user.approved_end_date
            oldEndDate = (newDay > user.approved_end_date) ? newDay : user.approved_end_date;
    
            const data = {
                user_id: idUser,
                oldEndDate: oldEndDate,
                packageApproved: packageApproved
            };
            const response = await postPackage(token, {data})
            if (response) {
                setShowModalTransfer(true);
            }
        }
    };

    const handClickClose = () => {
        setShowModalTransfer(false);
        navigate('/information');
    };
    

    return (

        <div className="DetailPackage">
            <div className="DetailPackage_bg"></div>
            <div className="DetailPackage_content">
                <div className="DetailPackage_content_header">
                    <div className="DetailPackage_content_header_title">
                        <h3>Đơn hàng</h3>
                    </div>
                </div>
                <div className="DetailPackage_content_body">
                    <div className="DetailPackage_content_body_oder">
                        <div className="DetailPackage_content_body_oder_title">Thông tin</div>
                        <div className="DetailPackage_content_body_oder_item">
                            <div className="DetailPackage_content_body_oder_item_title">Người dùng:</div>
                            <div>{user.name}</div>
                        </div>
                        <div className="DetailPackage_content_body_oder_item">
                            <div className="DetailPackage_content_body_oder_item_title">Id:</div>
                            <div>{idUser}</div>
                        </div>
                        <div className="DetailPackage_content_body_oder_item">
                            <div className="DetailPackage_content_body_oder_item_title">Email:</div>
                            <div>{user.email}</div>
                        </div>
                    </div>
                    <div className="DetailPackage_content_body_checkout">
                        <div className="DetailPackage_content_body_checkout_title">Thông tin gói đã chọn</div>
                        <div className="DetailPackage_content_body_checkout_item">
                            <div className="DetailPackage_content_body_checkout_item_title">
                                Tên gói:
                            </div>
                            <div>
                                {packageName}
                            </div>
                        </div>
                        <div className="DetailPackage_content_body_checkout_item" style={{marginBottom: '20px'}}>
                            <div className="DetailPackage_content_body_checkout_item_title">
                                Giá:
                            </div>
                            <div>
                                {price} vnđ
                            </div>
                        </div>
                        <div className="DetailPackage_content_body_checkout_total">
                            <div style={{fontWeight: 'bold'}}>Thanh toán</div>
                            <div style={{color: 'red', fontWeight: 'bold'}}>{price} vnđ</div>
                        </div>
                        <div className="DetailPackage_content_body_checkout_methods">
                            <div className="DetailPackage_content_body_checkout_methods_title">
                                Phương thức thanh toán
                            </div>
                            <div className="DetailPackage_content_body_checkout_methods_chose">
                                <label className="DetailPackage_content_body_checkout_methods_chose_checkbox">
                                    <input
                                        type="radio"
                                        name="payment_option"
                                        value="gateways"
                                        checked={paymentOption === 'gateways'}
                                        onChange={(e) => setPaymentOption(e.target.value)}
                                    />
                                    <span className="checkmark"></span>
                                    <span>Cổng thanh toán</span>
                                </label>
                                <label className="DetailPackage_content_body_checkout_methods_chose_checkbox">
                                    <input
                                        type="radio"
                                        name="payment_option"
                                        value="transfer"
                                        checked={paymentOption === 'transfer'}
                                        onChange={(e) => setPaymentOption(e.target.value)}
                                    />
                                    <span className="checkmark"></span>
                                    <span>Chuyển khoản</span>
                                </label>
                            </div>
                        </div>
                        <div className="DetailPackage_content_body_checkout_btn" onClick={handPayment}>
                            HOÀN TẤT ĐƠN HÀNG
                        </div>
                    </div>
                </div>
            </div>
            
            {showModalTransfer && (
                <div className="ModalTransfer">
                    <div className="ModalTransfer_content">
                        <div className="ModalTransfer_content_title">
                            Đơn hàng của bạn đã được gửi đến MegaMesss
                        </div>
                        <div className="ModalTransfer_content_body">
                            <div className="ModalTransfer_content_body_title">
                                Vui lòng chuyển khoản theo thông tin sau:
                            </div>
                            <div className="ModalTransfer_content_body_content">
                                <div className="ModalTransfer_content_body_content_qr">
                                    <img src="/image/QR.jpg" alt="QR" />
                                </div>
                                <div className="ModalTransfer_content_body_content_info">
                                    <div>
                                        <span style={{fontWeight: 'bold', color: 'green', marginRight: '5px', fontSize: '20px'}}>Số tiền:</span>
                                        <span style={{color: 'red', fontSize: '20px', fontWeight: 'bold'}}>{price} vnđ</span>
                                    </div>
                                    <div style={{marginTop: '10px', fontWeight: 'bold', color: 'green', fontSize: '20px'}}>
                                        Nội dung chuyển khoản:
                                    </div>
                                    <div style={{marginTop: '10px', fontSize: '20px', fontWeight: 'bold', color: 'red', padding: '0px 20px'}}>
                                        <div>
                                            <span>ID</span>
                                            <span>{idUser}</span>
                                        </div>
                                        <div>
                                            <span>{packageName}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ModalTransfer_content_body_close">
                                <div className="ModalTransfer_content_body_close_btn" onClick={handClickClose}>
                                    Đóng
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DetailPackage;