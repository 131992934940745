import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initFacebookSdk } from './configs/init-facebook-sdk'

initFacebookSdk().then(() => {
  console.log('Facebook SDK đang tạo');
}).catch(error => {
  console.error('Lỗi khi khởi tạo Facebook SDK:', error);
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

