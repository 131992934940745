import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const apiFanpage = axios.create({
    baseURL: apiUrl,
});


//hàm post thông tin fanpage của user lên db
export const postFanpage = async (token, data) => {
    try {
        const response = await apiFanpage.post(`/api/user/fanpages`, data, {
            headers: {
                'Content-Type': 'application/json', // Example header
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

//hàm lấy fanpage bằng userId
export const getFanpageByUser = async (token, userId) => {
    try {
        const response = await apiFanpage.get(`/api/fanpages/${userId}`, {
            headers: {
                'Content-Type': 'application/json', // Example header
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

//hàm lấy chiến dịch
export const getCampaigns = async (token, page_id) => {
    try {
        const response = await apiFanpage.get(`api/fanpages/${page_id}/campains`, {
            headers: {
                'Content-Type': 'application/json', // Example header
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        throw error
    }
}

//hàm lấy danh sách khách hàng fanpage
export const getFanpagesCustomer = async (token, page_id) => {
    try {
        const response = await apiFanpage.get(`api/fanpages/${page_id}/customers`, {
            headers: {
                'Content-Type': 'application/json', // Example header
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export default apiFanpage;