import React from "react";
import './ModalSetting.scss';


const ModalSetting = (props) => {

    const handCloseModalSetting = () => {
        props.onCloseModal(false);
    }

    return (
        <div className="modalSetting">
            <div className="modalSetting_container">
                <iframe 
                    width="650"
                    height="400"
                    src="https://www.youtube-nocookie.com/embed/oOjFwTajS0k?si=ouLOaBfFWafgzOef"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                />
                <div className="modalSetting_close">
                    <div className="modalSetting_close_btn" onClick={handCloseModalSetting}>
                        Đóng
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSetting;