import React, {  useEffect } from "react";
import "./style.scss";
// import { useTranslation } from "react-i18next";
// import { getFanpageByUser } from "../../api/apiFanpage";
// import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import dayjs from "dayjs";
// import Popup from "../../components/Popup/Popup";

const Information = (props) => {
  // const navigate = useNavigate();
  // const userData = props.user;
  // const idUser = localStorage.getItem("mfmUserID");
  // const { t } = useTranslation();
  // const [fanpage, setFanpage] = useState([]);
  const mfmUserID = localStorage.getItem("mfmUserID");
  const mfmToken = localStorage.getItem("mfmToken");
  // const [showPopup, setShowPopup] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);

  // useEffect(() => {
  //   getFanpage();
  //   setShowPopup(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (mfmUserID && mfmToken) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('mfmToken')
      localStorage.removeItem('mfmUserID')
    }
  }, [mfmToken, mfmUserID]);

  // useEffect(() => {
  //   const isPopupHidden = localStorage.getItem("popupHidden");

  //   if (isPopupHidden === "true") {
  //     setShowPopup(false); // Nếu đã chọn "check" trước đó, không hiển thị popup khi truy cập trang web lần sau
  //   }
  // }, [setShowPopup]);

  // const getFanpage = async () => {
  //   try {
  //     const response = await getFanpageByUser(mfmToken, mfmUserID);
  //     return setFanpage(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleCheckChange = () => {
  //   setIsChecked(!isChecked); // Thay đổi trạng thái "check"
  // };

  return (
    <>
      <div className="Information_changePage">
        <div className="Information_changePage_box">
            <div className="Information_changePage_box_title">Chân thành cảm ơn quý khách đã ủng hộ và sử dụng app của chúng tôi thời gian qua</div>
            <div>Để nâng cao chất lượng dịch vụ và phát hành những tính năng mới trong thời gian sắp tới</div>
            <div>chúng tôi dời địa chỉ domain về <a style={{color: 'red'}} href="https://www.megamess.info.vn/vi">www.megamess.info.vn</a></div>
            <div>
              Quý khách đăng nhập, sử dụng sẽ có trải nghiệm tốt hơn và chờ những tính năng mới phát hành free nhé!!!
            </div>
            <div className="Information_changePage_box_action">
              <a className="Information_changePage_box_action_btn1" href="https://www.megamess.info.vn/vi">
                Chuyển trang ngay
              </a>
              <a className="Information_changePage_box_action_btn2" href="https://www.fshare.vn/file/PNU3KBKM7UFA">
                Download extension mới
              </a>
            </div>
        </div>
      </div>
      <div className="Information">
        <div className="Information_bg"></div>
        <div className="Information_content">
          {/* phần header */}
          {/* <div className="Information_content_header">
            <div className="Information_content_header_title">
              <h3>{t("Information.Information")}</h3>
              <div
                className="Information_upgrade"
                onClick={() => navigate("/BettingPackage")}
              >
                {t("Upgrade")}
              </div>
            </div>
            <div className="Information_content_header_flex">
              <div className="Information_content_header_card">
                <div className="Information_content_header_card_item">
                  <div className="Information_content_header_card_item_body">
                    <div className="Information_content_header_card_item_body_row">
                      <div>
                        <h4>{t("Information.User")}:</h4>
                      </div>
                      <div>
                        <h3>{userData?.name}</h3>
                      </div>
                    </div>
                    <div className="Information_content_header_card_item_body_row">
                      <div>ID:</div>
                      <div>{idUser}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Information_content_header_card">
                <div className="Information_content_header_card_item">
                  <div className="Information_content_header_card_item_body">
                    <div className="Information_content_header_card_item_body_row">
                      <div>
                        <h4>Gói</h4>
                      </div>
                      <div>
                        <h3>{userData && userData.approved_package}</h3>
                      </div>
                    </div>
                    <div className="Information_content_header_card_item_body_row">
                      <div>Ngày hết hạn:</div>
                      <div>
                        {userData &&
                          dayjs(userData.approved_end_date).format(
                            "DD/MM/YYYY"
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* hết phần header */}
          {/* phần thông tin fanpage */}
          {/* <div className="Information_page">
            <div className="Information_page_card">
              <div className="Information_page_card_body">
                <div className="Information_page_card_body_header">
                  <h4>Fanpage</h4>
                </div>
                <div className="Information_page_card_body_table">
                  <table className="Information_page_card_body_table_tablePage">
                    <thead className="Information_page_card_body_table_tablePage_thead">
                      <tr>
                        <th>Tên Fanpage</th>
                        <th>Id Fanpage</th>
                        <th>Đồng bộ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fanpage.map((fanpage) => (
                        <tr
                          className="Information_page_card_body_table_tablePage_tr"
                          onClick={() =>
                            navigate(`/Fanpage/${fanpage.page_id}`)
                          }
                          key={fanpage.id}
                        >
                          <td className="Information_page_card_body_table_tablePage_tr_tdName">
                            <img src={fanpage.url} alt="Fanpage" />
                            <div>{fanpage.page_name}</div>
                          </td>
                          <td>{fanpage.page_id}</td>
                          <td>
                            {moment(fanpage.update_at).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="Information_page_card_body_footer"></div>
              </div>
            </div>
          </div> */}
          {/* hết phần này tin fanpage */}
        </div>
      </div>

      {/* {showPopup && (
        <Popup
          setShowPopup={setShowPopup}
          isChecked={isChecked}
          handleCheckChange={handleCheckChange}
        />
      )} */}
    </>
  );
};

export default Information;
