import React, { useEffect, useState, useRef } from "react";
import './Campaigns.scss';
import { getCampaigns } from "../../api/apiFanpage";

const Campaigns = (props) => {
    const {fanpageId, selectedFanpage} = props;
    const mfmToken = localStorage.getItem('mfmToken');
    const [isLoading, setIsLoading] = useState(true);
    const [campaignsList, setCampaignsList] = useState(null);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const contentRef = useRef(null);

    const getCampaign = async () => {
        try {
            const response = await getCampaigns(mfmToken, fanpageId);
            if (response) {
                const listUid = response.data.list_uid;
                if (Object.keys(listUid).length > 0) {
                    const newDataArray = Object.keys(listUid).map((key, index) => ({
                        "data": {
                            "campain_name": `${response.data.campain_name}-tệp-${index + 1}`,
                            "customer_message": {
                                "text": response.data.customer_message.text,
                                "image": response.data.customer_message.image,
                                "video": response.data.customer_message.video,
                            },
                            "list_uid": {
                                "0": listUid[key]
                            }
                        }
                    }));
        
                    setCampaignsList(newDataArray);
                } else {
                    setCampaignsList([response.data]);
                }
            }
        } catch (error) {
            console.error(error);
        }finally {
            setIsLoading(false); // Đánh dấu rằng dữ liệu đã được tải xong
        }
    }

    useEffect(() => {
        const contentDiv = contentRef.current;
        if (contentDiv) {
            const height = contentDiv.offsetHeight;
        
            if (height > 160) {
                contentDiv.style.height = '320px';
                contentDiv.style.overflowY = 'scroll';
            } else {
                contentDiv.style.height = 'auto';
                contentDiv.style.overflowY = 'unset';
            }
        }
    }, [selectedCampaign]);

    useEffect(() => {
        if (mfmToken && fanpageId) {
            getCampaign();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mfmToken, fanpageId]);

    const openExtension = () => {
        window.open(`https://facebook.com/?ref=mfm&token=${mfmToken}`, '_blank');
    };

    useEffect(() => {
        if (campaignsList && !selectedCampaign && campaignsList.length > 0) {
            handleItemClick(campaignsList[0]); // Chọn mục đầu tiên khi danh sách được tải
        }
    }, [campaignsList, selectedCampaign]);

    const handleItemClick = (item) => {
        setSelectedCampaign(item);
    }

    if(isLoading) {
        return (
            <div className="Campaigns">
                <div className="Campaigns_note">
                    <div className="Campaigns_note_title">
                        Đang tải dữ liệu...
                    </div>
                    
                </div>
            </div>
        )
    }

    if(!campaignsList) {
        return (
            <div className="Campaigns">
                <div className="Campaigns_note">
                    <div className="Campaigns_note_title">
                        Fanpage của bạn chưa có dữ liệu, hãy tiến hành quét khách hàng
                    </div>
                    <div
                        className="Campaigns_note_btn"
                        onClick={openExtension}
                    >
                        Quét ngay
                    </div>
                    
                </div>
            </div>
        )
    }

    if (campaignsList) {
        return (
            <div className="Campaigns">
                <div className="Campaigns_body">
                    <div className="Campaigns_body_left">
                        <div className="Campaigns_body_left_title">Danh sách tệp chiến dịch</div>
                        <div className="Campaigns_body_left_list">
                        {campaignsList.map((item, index) => (
                            <div 
                                className={`Campaigns_body_left_list_item ${selectedCampaign && selectedCampaign.data.campain_name === item.data.campain_name ? 'active-file' : ''}`} 
                                key={index}
                                onClick={() => handleItemClick(item)}
                            >
                                <div>
                                    {item.data.campain_name}
                                </div>
                                <div>
                                    Số khách: <span style={{ color: 'green' }}>{item.data.list_uid[0].length}</span>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="Campaigns_body_right">
                        <div className="Campaigns_body_right_name">
                            Tên tệp: {selectedCampaign ? selectedCampaign.data.campain_name : ''}
                        </div>
                        <div className="Campaigns_body_right_file">
                            Tổng số khách hàng nhận tin của tệp: {selectedCampaign ? selectedCampaign.data.list_uid[0].length : ''}
                        </div>
                        <div
                            className="Campaigns_body_right_iphone"
                            style={{
                                backgroundImage: `url(/image/iphone.png)`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}
                        >
                            <div
                                className="Campaigns_body_right_iphone_content"
                                ref={contentRef}
                            >
                                <div style={{
                                            padding: '5px 5px 5px 10px',
                                            position: 'relative',
                                            top: '20px',
                                            left: '14px'
                                        }}>
                                    {selectedCampaign && 
                                        <>
                                            {selectedCampaign.data.customer_message.text && 
                                                <div className="Campaigns_body_right_iphone_content_text">
                                                    {selectedCampaign.data.customer_message.text}
                                                </div>
                                            }
                                            <div style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: '4px', marginTop: '4px'}}>
                                                {selectedCampaign.data.customer_message.image.length !== 0 && selectedCampaign.data.customer_message.image.map((item, index) => (
                                                    // <div  className="Campaigns_body_right_iphone_content_img">
                                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                                        <img key={index} src={item} alt="image" style={{ width: '100%', height: '100%', borderRadius: '4px' }}/>
                                                    // </div>
                                                    ))
                                                }   
                                            </div>
                                            {selectedCampaign.data.customer_message.video.length !== 0 && 
                                                <div className="Campaigns_body_right_iphone_content_video">
                                                    <video controls style={{ maxWidth: '100%', maxHeight: '100%', margin: '4px 0px' }}>
                                                        <source src={selectedCampaign.data.customer_message.video} type="video/mp4" />
                                                    </video>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                                <div className="Campaigns_body_right_iphone_content_avatar">
                                    <img src={selectedFanpage.url} alt="avatar fanpage"/>
                                </div>
                            </div> 
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Campaigns