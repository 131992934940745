import React, { useEffect, useState } from "react";
import './CustomerFanpage.scss';
import { getFanpagesCustomer } from "../../api/apiFanpage";
import dayjs from 'dayjs';

const CustomerFanpage = (props) => {
    const { fanpageId } = props;
    const mfmToken = localStorage.getItem('mfmToken');
    const [customerFanpage, setCustomerFanpage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const customersPerPage = 50; // Số lượng khách hàng trên mỗi trang

    const getCustomerFanpage = async () => {
        try {
            const response = await getFanpagesCustomer(mfmToken, fanpageId);
            setCustomerFanpage(response);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Đánh dấu rằng dữ liệu đã được tải xong
        }
    }

    useEffect(() => {
        if (mfmToken && fanpageId) {
            getCustomerFanpage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mfmToken, fanpageId]);

    const openExtension = () => {
        window.open(`https://facebook.com/?ref=mfm&token=${mfmToken}`, '_blank');
    }

    const handleClickPrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleClickNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    if (isLoading) {
        return (
            <div className="CustomerFanpage">
                <div className="CustomerFanpage_note">
                    <div className="CustomerFanpage_note_title">
                        Đang tải dữ liệu...
                    </div>
                </div>
            </div>
        );
    }

    if (!customerFanpage) {
        return (
            <div className="CustomerFanpage">
                <div className="CustomerFanpage_note">
                    <div className="CustomerFanpage_note_title">
                        Fanpage của bạn chưa có dữ liệu, hãy tiến hành quét khách hàng
                    </div>
                    <div
                        className="CustomerFanpage_note_btn"
                        onClick={openExtension}
                    >
                        Quét ngay
                    </div>
                </div>
            </div>
        );
    }

    const totalPages = Math.ceil(customerFanpage.length / customersPerPage);

    return (
        <div className="CustomerFanpage">
            <div className="CustomerFanpage_list">
                <div className="CustomerFanpage_list_card">
                    <div className="CustomerFanpage_list_card_body">
                        <div className="CustomerFanpage_list_card_body_header">
                            <div className="CustomerFanpage_list_card_body_header_title">
                                <div>
                                    Danh sách khách hàng (Tổng cộng {customerFanpage.length} khách)
                                </div>
                            </div>
                        </div>
                        <div className="CustomerFanpage_list_card_body_table">
                            <table className="CustomerFanpage_list_card_body_table_content">
                                <thead className="CustomerFanpage_list_card_body_table_content_thead">
                                    <tr>
                                        <th>STT</th>
                                        <th>UID</th>
                                        <th>Tên</th>
                                        <th>Giới tính</th>
                                        <th>SĐT</th>
                                        <th>FbTags</th>
                                        <th>MegamessTags</th>
                                        <th>TimeInbox</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerFanpage.slice((currentPage - 1) * customersPerPage, currentPage * customersPerPage).map((item, index) => (
                                        <tr className="CustomerFanpage_list_card_body_table_content_tr" key={item.uid}>
                                            <td>{(currentPage - 1) * customersPerPage + index + 1}</td>
                                            <td>{item.uid}</td>
                                            <td>
                                                <div className="CustomerFanpage_list_card_body_table_content_tr_tdName">
                                                    <img src={item.avatar} alt="avatar" />
                                                    <div>
                                                        {item.customername}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{item.gender === 'FEMALE' ? 'Nữ' : item.gender === 'MALE' ? 'Nam' : ''}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.fbTags}</td>
                                            <td>{item.megamessTags}</td>
                                            <td>{dayjs(Number(item.lastinboxtime)).format('DD/MM/YYYY')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="CustomerFanpage_list_card_body_footer">
                            <button
                                onClick={handleClickPrev}
                                disabled={currentPage === 1}
                            >
                                Prev
                            </button>
                            <span>{`Trang ${currentPage} / ${totalPages}`}</span>
                            <button
                                onClick={handleClickNext}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerFanpage;
