import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import RedeemIcon from '@mui/icons-material/Redeem';
import HelpIcon from '@mui/icons-material/Help';
import ModalSetting from '../ModalSetting/ModalSetting';
//import PagesIcon from '@mui/icons-material/Pages';
import './style.scss';

const Navbar = () => {
  const { t } = useTranslation();
  const mfm_token = localStorage.getItem('mfmToken');
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [showModalSetting, setShowModalSetting] = useState(false);

  const openExtension = () => {
    window.open(`https://facebook.com/?ref=mfm&token=${mfm_token}`, '_blank');
  }

  const openDownload = () => {
    window.open('https://www.fshare.vn/file/2FOXDSL9BFY9', '_blank');
  }

  const openHelp = () => {
    window.open('https://m.me/205088869363680', '_blank');
  }

  const openHelpNavbar = () => {
    setIsHelpOpen(!isHelpOpen);
    // Thực hiện các hành động khác khi nhấp vào nút "Hướng dẫn"
  };

  const handOpenVideoSetting = () => {
    setShowModalSetting(true);
  }

  return (
    <div className='Navbar'>
      <div className='Navbar_logo'>
        <img width={200} src="/image/Megamess.svg" alt="logo" />
      </div>
      <div className='Navbar_menu'>
        <nav>
          <ul>
            <li>
              <Link
                to="/Information"
                className='Navbar_link'
              >
                <HomeIcon />
                <div>{t('Information.Information')}</div>
              </Link>
            </li>
            <li>
              <Link
                to="/BettingPackage"
                className='Navbar_link'
              >
                <RedeemIcon />
                Chọn gói cước
              </Link>
            </li>
            <li className='Navbar_link' onClick={openHelpNavbar}>
                <HelpIcon />
                Hướng dẫn
            </li>
            {isHelpOpen && (
              <div className='Navbar_help'>
                <div
                  className='Navbar_help_seting'
                  onClick={handOpenVideoSetting}
                >
                  Video hướng dẫn cài đặt
                </div>
              </div>
            )}
          </ul>
          <div className='Navbar_btn'>
            <div className='Navbar_btn_helpFb' onClick={openHelp}>Hỗ trợ</div>
            <div className='Navbar_btn_campaign' onClick={openExtension}>Tạo chiến dịch</div>
            <div className='Navbar_btn_file' onClick={openDownload}>Tải cài đặt</div>
          </div>
        </nav>
      </div>
      {showModalSetting && <ModalSetting onCloseModal={setShowModalSetting} />}
    </div>
  );
};

export default Navbar;