import React, { useEffect, useState } from "react";
import "./Fanpage.scss";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getFanpageByUser } from "../../api/apiFanpage";
import CustomerFanpage from "../../components/CustomerFanpage/CustomerFanpage";
import Campaigns from "../../components/Campaigns/Campaigns";

const Fanpage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [selectedFanpage, setSelectedFanpage] = useState(null);
  const [contentType, setContentType] = useState('CUSTOMER');
  
  const mfmUserID = localStorage.getItem('mfmUserID');
  const mfmToken = localStorage.getItem('mfmToken');

  useEffect(() => {
    const fetchFanpage = async () => {
      try {
        const response = await getFanpageByUser(mfmToken, mfmUserID);
        
        // Lọc fanpage trùng id
        const uniqueFanpages = response.filter((fanpage, index, self) => 
          index === self.findIndex((f) => f.page_id === fanpage.page_id)
        );

        // Tìm fanpage dựa trên id từ URL
        const foundFanpage = uniqueFanpages.find(fanpage => fanpage.page_id === id);
        
        if (foundFanpage) {
          setSelectedFanpage(foundFanpage);
        } else {
          console.warn(`Fanpage with id ${id} not found`);
        }

      } catch (error) {
        console.error(error);
      }
    };

    fetchFanpage();
  }, [id, mfmToken, mfmUserID]);

  const handleCustomerClick = () => {
    setContentType('CUSTOMER');
  }

  const handleCampaignClick = () => {
    setContentType('CAMPAIGN');
  }

  return (
    <div className="Fanpage">
      <div className="Fanpage_bg"></div>
      <div className="Fanpage_content">
        {/* phần header */}
        <div className="Fanpage_content_header">
          <div className="Fanpage_content_header_title">
            {selectedFanpage ? (
              <div className="Fanpage_content_header_logo">
              <img
                src={selectedFanpage.url}
                alt="logo"
              />
              <h3>{selectedFanpage.page_name}</h3>
            </div>
            ) : 'Loading...'}
            <div className="Fanpage_upgrade">
              {t('Upgrade')}
            </div>
          </div>
        </div>
        {/* hết phần header */}
        <div className="Fanpage_body">
          <div className="Fanpage_body_content">
            <div className="Fanpage_body_content_title">
              <div
                className={`Fanpage_body_content_title_text ${contentType === 'CUSTOMER' ? 'active' : ''}`}
                onClick={handleCustomerClick}
              >
                Số lượng khách hàng
              </div>
              <div
                className={`Fanpage_body_content_title_text ${contentType === 'CAMPAIGN' ? 'active' : ''}`}
                onClick={handleCampaignClick}
              >
                Chiến dịch gần nhất
              </div>
            </div>
            {contentType === 'CUSTOMER' && (
              <CustomerFanpage fanpageId={id}/>
            )}
            {contentType === 'CAMPAIGN' && (
              <Campaigns fanpageId={id} selectedFanpage={selectedFanpage}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fanpage;
