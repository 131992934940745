import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import AdminUsers from '../../components/Admin_Users/AdminUsers';
import Information from '../Information/Information';
import Fanpage from '../Fanpage/Fanpage';
import BettingPackage from '../BettingPackage/BettingPackage';
import DetailPackage from '../DetailPackage/DetailPackage';
import { getCurrentUser } from '../../api/apiUsers';
import VerifyPhone from '../../components/VerifyPhone/VerifyPhone';
import './style.scss';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { i18n } = useTranslation();

  const getUser = async (token) => {
    try {
      const response = await getCurrentUser(token);
      let mfmUser = {
        id_user: response.id,
        fb_id: response.fb_id,
        name: response.username,
        email: response.email,
        avatar: response.avatar,
        access_token_fb: response.access_token_fb,
        token_mfm: token,
        approved_package: response.approved_package,
        approved_end_date: response.approved_end_date,
        phone_number: response.phone_number,
      };
      setUser(mfmUser);
      i18n.changeLanguage(response.lang);
    } catch (error) {
      console.error('Lỗi lấy thông tin người dùng:', error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const mfm_token = localStorage.getItem('mfmToken');
    const isLoggedIn = token && mfm_token;
    // Nếu không đăng nhập, chuyển hướng về trang login
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      getUser(mfm_token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserAfterPhoneVerification = () => {
    getUser(localStorage.getItem('mfmToken'));
  };

  return (
    <div className='Home'>
      { user?.phone_number === null &&
        <VerifyPhone userData={user} updateUserAfterPhoneVerification={updateUserAfterPhoneVerification}/>
      }
      <Navbar />
      <div className='Home_Header'>
        <AdminUsers user={user} />
      </div>
      <div className='Home_Content'>
        <div className='ForPageViews'>
          <Routes>
            <Route path="Information" element={<Information user={user} />} />
            <Route path="Fanpage/:id" element={<Fanpage />} />
            <Route path="BettingPackage" element={<BettingPackage />} />
            <Route path="DetailPackage" element={<DetailPackage user={user} />} />
            <Route index element={<Navigate to="Information" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};


export default Home;