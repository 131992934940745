import React from "react";
import "./PrivacyTerms.scss";

const PrivacyTerms = () => {

    return (
        <div className="PrivacyTerms">
            <div className="PrivacyTerms_container">
                <div className="PrivacyTerms_logo">
                    <img src="/image/Megamess.svg" alt="logo" />
                </div>
                <div className="PrivacyTerms_content" id="EnglishVersion">
                    <div className="PrivacyTerms_title">
                        Privacy Policy
                    </div>
                    <div>
                        <i>{`[English version]`}</i>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <i><a href="#VietnameseVersion">{`[Đến phiên bản Tiếng Việt]`}</a></i>
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Last updated: May 14, 2024`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`At MEGAMESS , Inc. (“MEGAMESS ,” “we, “us”) we consider the privacy of the visitors to our website  and the security of their personal information, to be extremely important. This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.`}
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Interpretation and Definitions
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Interpretation
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.`}
                    
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Definitions
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`For the purposes of this Privacy Policy:`}
                        <div style={{ marginBottom: "10px", paddingLeft: "20px" }}>
                            {`•	Account means a unique account created for You to access our Service or parts of our Service.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Company (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to MEGAST SOFTWARE LLC.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Country refers to: Vietnam`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Personal Data is any information that relates to an identified or identifiable individual.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Service refers to the Website.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Website refers to MEGAMESS , accessible from https://megamess.vn/`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.`}
                        </div>
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Collecting and Using Your Personal Data
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Types of Data Collected
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Personal Data
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:`}
                        <div style={{ marginBottom: "10px", paddingLeft: "20px" }}>
                            {`•	Email address`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Phone number`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	First name and last name`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Sex`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Usage Data`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Timezone`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Language`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Individual's business information from Facebook.`}
                            <div style={{ marginBottom: "10px" }}></div>
                        </div>
                        {`For example, your gender for the page for the purpose of: personalizing the experience or as a gender-based recommendation. Use gender-specific language such as correct pronouns and titles. Personal business information from Facebook: support personal business advertising, link applications that support Facebook Fanpage.`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Usage Data
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Usage Data is collected automatically when using the Service.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`This usage data may include information such as your computer’s Internet Protocol address (IP address), browser type, browser version, the pages of our system you visit, date and time you visit, the time you spend on these sites, unique device identifiers and other diagnostic data.`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Tracking Technologies and Cookies
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`We use cookies and similar tracking technologies to track activities on our systems and keep certain information.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Cookies are files with small amounts of data that may include an anonymous unique identifier. Cookies are sent to the browser from a website and stored on your device. Tracking technologies used are signals, tags, and scripts to collect and track information and to improve and analyze Our Service.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use certain parts of our system.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Examples of Cookies we use:`}
                        <div style={{ marginBottom: "10px", paddingLeft: "20px" }}>
                            {`– Session cookies: we use session cookies to operate our system.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`– Preference cookies: we use preference cookies to remember your preferences and various settings.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`– Security cookies: we use security cookies for security purposes.`}
                        </div>
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Use of Your Personal Data
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`The Company may use Personal Data for the following purposes:`}
                        <div style={{ marginBottom: "10px", paddingLeft: "20px" }}>
                            {`•	To provide and maintain our Service, including to monitor the usage of our Service.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	To manage Your requests: To attend and manage Your requests to Us.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {``}
                            <div style={{ marginBottom: "10px" }}></div>
                        </div>
                        {`We may share Your personal information in the following situations:`}
                        <div style={{ marginBottom: "10px", paddingLeft: "20px" }}>
                            {`•	With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	With Your consent: We may disclose Your personal information for any other purpose with Your consent.`}
                            <div style={{ marginBottom: "10px" }}></div>
                        </div>
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Retention of Your Personal Data
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.`}
                        <div style={{ marginBottom: "10px" }}></div>
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Transfer of Your Personal Data
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.`}
                        <div style={{ marginBottom: "10px" }}></div>
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Disclosure of Your Personal Data
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Business Transactions
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Law enforcement
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Other legal requirements
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:`}
                        <div style={{ marginBottom: "10px", paddingLeft: "20px" }}>
                            {`•	Comply with a legal obligation`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Protect and defend the rights or property of the Company`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Prevent or investigate possible wrongdoing in connection with the Service`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Protect the personal safety of Users of the Service or the public`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Protect against legal liability`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`•	Other legal requirements`}
                        </div>
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Security of Your Personal Data
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Children & Minor Privacy
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Our system does not address anyone under the age of 18 (“Minor”). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you know that your child has provided us with personal data, please contact us. `}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`If we become aware that we have collected personal data from minor without verification of parental consent, we will take steps to remove that information from our servers.`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Links to Other Websites
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party’s site. We strongly advise You to review the Privacy Policy of every site You visit.
                        We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                        `}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Data deletion
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Users have the right to submit a request to delete your data that Megamess  has collected by sending an email to stark.nguyen1935@gmail.com.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Megamess  will perform the deletion if your request does not violate local laws and does not affect Megamess ’s legitimate rights.`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Changes to this Privacy Policy
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the “Last updated” date at the top of this Privacy Policy.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.`}
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Contact Us
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`If you have any questions about this Privacy Policy, You can contact us:`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`By email: stark.nguyen1935@gmail.com`}
                    </div>
                </div>
                <div className="PrivacyTerms_content" id="VietnameseVersion">
                    <div className="PrivacyTerms_title">
                        Chính sách bảo mật
                    </div>
                    <div>
                        <i>{`[Phiên bản tiếng Việt]`}</i>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <i><a href="#EnglishVersion">{`[Go English version]`}</a></i>
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Có hiệu lực từ ngày 14/5/2024`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Dịch vụ gửi tin an toàn Megamess hoạt động trên hệ thống website http://megamess.vn`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Trang này thông báo đến bạn chính sách của chúng tôi đối với việc thu thập, sử dụng, tính công khai của dữ liệu cá nhân khi bạn sử dụng hệ thống của Megamess  và lựa chọn của bạn đối với những dữ liệu đấy. Chính sách bảo mật của Megamess  được tạo ra với sự trợ giúp của Phần mềm tạo chính sách bảo mật miễn phí.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Chúng tôi sử dụng dữ liệu của bạn để cung cấp và cải thiện dịch vụ. Với việc sử dụng dịch vụ của chúng tôi, bạn đồng ý cho phép thu thập và sử dụng các thông tin theo chính sách này.Trừ khi được định nghĩa khác trong chính sách bảo mật này, các điều khoản được sử dụng trong chính sách quyền riêng tư này có cùng ý nghĩa như trong điều khoản và điều kiện của chúng tôi, bạn có thể truy cập tại http://megamess.vn`}
                    </div>
                    <div className="PrivacyTerms_content_title_big">
                        Nội dung
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Thông tin thu thập và sử dụng
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Chúng tôi thu thập một số loại thông tin khác nhau cho các mục đích để cung cấp và cải thiện hệ thống của chúng tôi.`}
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Các loại dữ liệu được thu thập
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Dữ liệu cá nhân
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Trong khi sử dụng dịch vụ của chúng tôi, chúng tôi yêu cầu bạn cung cấp cho chúng tôi một số thông tin nhận dạng cá nhân, những thông tin mà chúng tôi có thể liên lạc hoặc định danh bạn ( dữ liệu cá nhân). Thông tin định dạng cá nhân có thể bao gồm, nhưng không giới hạn:`}
                    </div>
                    <div className="PrivacyTerms_content_text" style={{ paddingLeft: "20px" }}>
                        {`1.	Địa chỉ Email.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`2.	Họ và tên.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`3.	Số điện thoại.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`4.	Cookies và dữ liệu sử dụng.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`5.	Giới tính.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`6.	Múi giờ.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`7.	Ngôn ngữ.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`8.	Thông tin doanh nghiệp của cá nhân từ Facebook.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Ví dụ, giới tính của bạn cho trang với mục đích: cá nhân hóa trải nghiệm hoặc là đề xuất dự trên giới tính. Sử dụng ngôn ngữ theo giới tính như là đại từ và chức danh chính xác, Thông tin doanh nghiệp của cá nhân từ Facebook: hỗ trợ quảng cáo daonh nghiệp của cá nhân, liên kết các ứng dụng hỗ trợ Fanpage Facebook.`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Dữ liệu sử dụng
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Chúng tôi cũng có thể thu thập thông tin về cách hệ thống được truy cập như thế nào và sử dụng (dữ liệu sử dụng). Dữ liệu sử dụng này có thể bao gồm thông tin như là địa chỉ Giao thức Internet trên máy tính (địa chỉ IP), loại trình duyệt, phiên bản trình duyệt, các trang của hệ thống chúng tôi mà bạn truy cập, ngày giờ bạn truy cập, thời gian bạn sử dụng trên những trang này, nhận dang thiết bị duy nhất và dữ liệu chuẩn đoán khác.Tracking và dữ liệu Cookies`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Chúng tôi sử dụng cookies và các công nghệ theo dõi tương tự để theo dõi các hoạt động trên hệ thống của chúng tôi và giữ một số thông tin nhất định.
                            Cookies là các tệp có lượng dữ liệu nhỏ có thể bao gồm một số nhận dạng duy nhất ẩn danh. Cookies được gửi đến trình duyệt từ một trang web và được lưu trữ trên thiết bị của bạn. Công nghệ theo dõi cũng được sử dụng là báo hiệu, thẻ, và lệnh để thu thập và theo dõi thông tin và để cải thiện và phân tích hệ thống của chúng tôi.
                        `}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Bạn có thẻ hướng dẫn trình duyệt của mình từ chối tất cả cookies hặc cho biết khi nào một cookie được gửi đi. Tuy nhiên, nếu bạn không chấp nhận cookies, bạn có thể không sử dụng được một số phần trong hệ thống của chúng tôi.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Ví dụ về Cookies chúng tôi sử dụng:`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`– Cookies phiên: chúng tôi sử dụng cookies phiên để vận hành hệ thống của chúng tôi.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`– Cookies ưu tiên: chúng tôi sử dụng cookies ưu tiên để ghi nhớ các tùy chọn của bạn và các cài đặt khác nhau.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`– Cookies bảo mật: chúng tôi sử dụng cookies bảo mật cho các mục đích bảo mật.`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Sử dụng dữ liệu
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Megamess sử dụng dữ liệu thu thập được cho các mục đích khác nhau:`}
                        <div style={{ marginPaddingLeft: "20px" }}>
                            {`1.	Cung cấp và duy trì hệ thống.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`2.	Thông báo cho bạn về những sự thay đổi trên hệ thống.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`3.	Cho phép bạn tham gia vào các tính năng tương tác của hệ thống chúng tôi khi bạn chọn để làm.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`4.	Cung cấp dịch vụ hỗ trợ khách hàng.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`5.	Cung cấp phân tích hoặc thông tin có giá trị để chúng tôi có thể cải thiện hệ thống.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`6.	Để giám sát việc sử dụng hệ thống.`}
                            <div style={{ marginBottom: "10px" }}></div>
                            {`7.	Để phát hiện, ngăn chặn và giải quyết các vấn đề kỹ thuật.`}
                        </div>
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Chuyển dữ liệu
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Thông tin của bạn, bao gồm cả dữ liệu cá nhân, có thể được chuyển đến và được duy trình trên các máy tính nằm bên ngoài tiểu bang, tỉnh, quốc gia hoặc khu vực ngoài quyền hạn của chính phủ nới luật bảo vệ dữ liệu có thể khác với những luật từ quyền hạn của bạn.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Nếu bạn đang ở ngoài Việt Nam và chọn cung cấp thông tin cho chúng tôi, vui lòng lưu ý rằng chúng tôi chuyển dữ liệu, bao gồm chuyển dữ liệu cá nhân đến Việt Nam và xử lý tại đó.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Sự đồng ý của bạn đối với chính sách bảo mật này,sau đó bạn gửi thông tin đó để thể hiện sự chấp thuận của bạn với việc chuyển giao này.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Megamess  sẽ thực hiện tất cả các bước cần thiết một cách hợp lý để đảm bảo rằng dữ liệu của bạn được xử lý một cách an toàn và phù hợp với chính sách bảo mật và sẽ không có chuyện chuyển dữ liệu cá nhân của bạn đến một tổ chức hoặc một quốc gia trừ khi có các biện pháp kiểm soát thích hợp bao gồm bảo mật dữ liệu của bạn và thông tin cá nhân khác.`}
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Tiết lộ dữ liệu
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Yêu cầu pháp lý
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Megamess  có thể tiết lộ dữ liệu cá nhân của bạn với thiện chí và tin rằng hành động đó là cần thiết để:`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`– Tuân thủ nghĩa vụ pháp lý.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`– Bảo vệ quyền lợi của Megamess .`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`– Ngăn chặn hoặc điều tra hành vi sai trái có thể xảy ra liên quan đến hệ thống.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`– Bảo vệ quyền an toàn cá nhân của người dùng hệ thống hoặc công cộng.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`– Bảo vệ khỏi trách nhiệm pháp lý.`}
                    </div>
                    <div className="PrivacyTerms_content_title_small">
                        Các nhà cung cấp hệ thống
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Chúng tôi có thể thuê các công ty và cá nhân bên thứ ba để hỗ trợ hệ thống của chúng tôi, để cung cấp hệ thống thay mặt chúng tôi, thực hiện các dịch vụ liên quan đến hệ thống, hỗ trợ chúng tôi phân tích các hệ thống của chúng tôi được sử dụng.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Những bên thứ ba ngày chỉ có quyền truy cập vào dữ liệu cá nhân của bạn để thực hiện các nhiệm vụ này thay mặt chúng tôi và có nghĩa vụ không tiết lộ hoặc sử dụng nó cho bất kỳ mục đích nào khác.`}
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Bảo mật dữ liệu
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Việc bảo mật dữ liệu của bạn rất quan trọng với chúng tôi, nhưng hãy nhớ rằng không có phương thức truyền tải nào qua Internet hoặc phương thức lưu trữ điện tử là an toàn 100%. Mặc dù chúng tôi cố gắng sử dụng các phương tiện được chấp nhận về mặt thương mại để bảo vệ dữ liệu cá nhân của bạn, nhưng chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của dữ liệu đó.`}
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Quyền riêng tư của trẻ em
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Hệ thống của chúng tôi không giải quyết bất kỳ ai dưới 18 tuổi (“Trẻ em”).Chúng tôi không cố ý thu thập thông tin nhận dạng cá nhân từ bất kỳ ai dưới 18 tuổi. Nếu bạn là cha mẹ hoặc người giám hộ và bạn biết rằng con bạn đã cung cấp dữ liệu cá nhân cho chúng tôi, vui lòng liên hệ với chúng tôi. Nếu chúng tôi biết rằng chúng tôi đã thu thập dữ liệu cá nhân từ trẻ em mà không có xác minh của sự đồng ý của cha mẹ, chúng tôi sẽ thực hiện các bước để xóa thông tin đó khỏi máy chủ của mình.`}
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Liên kết đến các trang web khác
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Hệ thống của chúng tôi có thể chứa các liên kết đến các trang web khác không do chúng tôi điều hành. Nếu bạn nhấp vào liên kết của bên thứ ba, bạn sẽ được dẫn đến trang web của bên thứ ba đó. Chúng tôi đặc biệt khuyên bạn nên xem lại chính sách bảo mật của mọi trang web bạn truy cập.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Chúng tôi không kiểm soát và không chịu trách nhiệm về nội dung, chính sách bảo mật hoặc thông lệ của bất kỳ trang web hoặc dịch vụ nào của bên thứ ba.`}
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Các thay đổi đối với chính sách bảo mật này
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Chúng tôi có thể cập nhật chính sách bảo mật của mình theo thời gian. Chúng tôi sẽ thông báo cho bạn về bất kỳ thay đổi nào bằng cách đăng chính sách bảo mật mới trên trang này.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Chúng tôi sẽ cho bạn biết qua email hoặc một thông báo nổi bật trên hệ thống của chúng tôi, trước khi thay đổi có hiệu lực và cập nhật “ngày có hiệu lực” ở đầu chính sách bảo mật này.`}
                        <div style={{ marginBottom: "10px" }}></div>
                        {`Bạn nên xem lại Chính sách Bảo mật này định kỳ để biết bất kỳ thay đổi nào. Các thay đổi đối với Chính sách Bảo mật này có hiệu lực khi chúng được đăng trên trang này.`}
                    </div>
                    <div className="PrivacyTerms_content_title">
                        Liên hệ chúng tôi
                    </div>
                    <div className="PrivacyTerms_content_text">
                        {`Nếu bạn có bất kỳ câu hỏi nào về Chính sách Bảo mật này, vui lòng liên hệ với chúng tôi:`}
                        <div style={{ marginBottom: "10px", paddingLeft: "20px" }}>
                            {`•	Qua email: stark.nguyen1935@gmail.com`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyTerms;