import React from "react";
import "./ServicePolicy.scss";

const ServicePolicy = () => {

    return (
        <div className="ServicePolicy">
            <div className="ServicePolicy_container">
                <div className="ServicePolicy_logo">
                    <img src="/image/Megamess.svg" alt="logo" />
                </div>
                <div className="ServicePolicy_content" id="EnglishVersion">
                    <div className="ServicePolicy_title">
                        MEGAMESS.VN Terms and Conditions of Service
                    </div>
                    <div>
                        <i>{`[English version]`}</i>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <i><a href="#VietnameseVersion">{`[Đến phiên bản Tiếng Việt]`}</a></i>
                    </div>
                    <div className="ServicePolicy_content_title">
                        Welcome to MEGAMESS
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`Welcome to our website. Thank you for using our products and services (“App”). By accessing our website and using our Application, you agree to the terms of service provided by MEGAST SOFTWARE LLC. Please check back often for our changes and carefully read the terms below:`}
                    </div>
                    <div className="ServicePolicy_content_title">
                        {`1. Use our app`}
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`You may use our application only if you can form a legally binding contract with MEGAST SOFTWARE LLC and comply with the terms and all applicable laws. When creating a MEGAMESS account, you must provide accurate and complete information. No unauthorized use of our Application. We may suspend or discontinue the Application if you do not comply with the terms or policies. Your use of the Application does not mean that you own any intellectual property rights in our Application or the content you access. Do not remove, obscure or alter any legal notices displayed in or in conjunction with our App. We may send you service notices, administrative messages and other information in connection with your use of the Application.`}
                    </div>
                    <div className="ServicePolicy_content_title">
                        2. Your account
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`MEGAST SOFTWARE LLC allows you to post content to MEGAMESS, including images, comments, links and other materials. You retain ownership of any intellectual property rights you have in such content. Any content you post or publish on MEGAMESS is referred to as “User Content”. You reserve all rights and are solely responsible for the User Content you post on MEGAMESS. Customers can refer to the payment methods in these terms and choose to apply accordingly`}
                    </div>
                    <div className="ServicePolicy_content_title">
                        3. General trading conditions
                    </div>
                    <div className="ServicePolicy_content_title_small">
                        a. General payments
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`The prices of products and services at MEGAMESS include VAT.`}
                        <div style={{marginBottom: 10}}></div>
                        {`MEGAST SOFTWARE LLC reserves the right to update and change service package prices from time to time. The rate change will take effect at the time you start your subscription to use the next service after the date of the rate change. By continuing to experience the service and make a transaction, you agree to accept the new price after it becomes effective.`}
                    </div>
                    <div className="ServicePolicy_content_title_small">
                        b. Service delivery process
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`MEGAMESS publishes service packages and corresponding fee schedule of each package right on the website https://megamess.vn/. The user makes a selection of the appropriate service package, payment method and confirmation of order creation. We will review your order and confirm service delivery immediately after receiving your payment`}
                        <div style={{marginBottom: 10}}></div>
                        {`In case the order is paid during office hours (from 8am on Monday to 12pm on Saturday): the order will be approved within 1 hours from the time MEGAMESS receives your payment.`}
                        <div style={{marginBottom: 10}}></div>
                        {`In case the order is paid outside of office hours, it will be approved within 4 – 8 hours from the time MEGAMESS receives the payment.`}
                        <div style={{marginBottom: 10}}></div>
                        {`After you complete the order, MEGAMESS will email the details of the order to you. If you want to change any information in the order (service package, invoice information, …), please contact us via hotline 0916961415 for support`}
                    </div>
                    <div className="ServicePolicy_content_title_small">
                        c. Payment mechanism
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`In order to bring customers satisfaction when shopping experience and flexibility in payment, MEGAMESS.VN provides the following payment methods:`}
                        <div style={{marginBottom: 10}}></div>
                        {`1. Payment via domestic ATM card.`}
                        <div style={{marginBottom: 10}}></div>
                        {`2. Payment via credit card, debit card.`}
                        <div style={{marginBottom: 10}}></div>
                        {`3. Bank transfer payment.`}
                        <div style={{marginBottom: 10}}></div>
                        {`4. Cash.`}
                        <div style={{marginBottom: 10}}></div>
                        {`In case there is an error in payment, you are obliged to notify and provide verification documents for the payment transaction to MEGAST SOFTWARE LLC for inspection and processing. Specifically, the error cases arise from your bank or card.`}
                    </div>
                    <div className="ServicePolicy_content_title_small">
                        {`d. Refund Policy`}
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`We always strive to better support your business with continuously upgraded software solutions. If there is anything you are not satisfied with and do not want to continue using, we are committed to refunding the cost to you in that month.`}
                    </div>
                    <div className="ServicePolicy_content_title_small">
                        e. Scope and purpose of personal information collection
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`We will collect various information when you want to buy products on MEGAMESS.VN. We collect, store and process your information for the purchase process and for future announcements and to provide services. Unlimited personal information: name, gender, date of birth, email, address, phone number, bank account details. You only provide us with accurate, non-misleading information and must notify us of any changes.`}
                    </div>
                    <div className="ServicePolicy_content_title_small">
                        f. Scope of information use
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`Your information is only used in the management and business activities of MEGAMESS and provided to the affiliated party. We only share collected information in necessary cases and as required by law to the following agencies, organizations and individuals:`}
                        <div style={{marginBottom: 10}}></div>
                        {`1. Payment intermediary: the bank you are using, in case there is an error when making a payment.`}
                        <div style={{marginBottom: 10}}></div>
                        {`2. Third party affiliated with MEGAMESS. These third parties only have access to your personal data to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.`}
                        <div style={{marginBottom: 10}}></div>
                        {`3. Competent state agencies`}
                    </div>
                    <div className="ServicePolicy_content_title">
                        4. Regulations on user transactions with third parties
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`MEGAMESS is absolutely not responsible for all your transactions with third parties during the use of products and services at MEGAMESS. When you use the product or transact with a third party, you understand and agree to be solely responsible for the risks that arise.`}
                    </div>
                    <div className="ServicePolicy_content_title">
                        5. Prohibited conduct
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`Acts that do not comply with Vietnamese law. Members must not take advantage of the provision and use of services of MEGAMESS to do the following:`}
                        <div style={{marginBottom: 10}}></div>
                        {`1. Destroying the State of the Socialist Republic of Vietnam, causing harm to national security, disturbing social order and safety, sabotaging the great national unity bloc, propagating war, terrorism, and hatred. enmity and conflict between peoples, ethnicities and religions;`}
                        <div style={{marginBottom: 10}}></div>
                        {`2. Propagating and inciting violence, lewdness, debauchery, crimes, social evils, superstition, and sabotage of the nation’s fine customs and traditions;`}
                        <div style={{marginBottom: 10}}></div>
                        {`3. Propagating and inciting violence, lewdness, debauchery, crimes, social evils, superstition, and sabotage of the nation’s fine customs and traditions;`}
                        <div style={{marginBottom: 10}}></div>
                        {`4. Disclosing state secrets, military secrets, security, economic, foreign affairs and other secrets prescribed by law;`}
                        <div style={{marginBottom: 10}}></div>
                        {`5. Disseminating information that distorts, slanders or insults the reputation of the organization, the honor and dignity of the individual;`}
                        <div style={{marginBottom: 10}}></div>
                        {`6. Insulting, mocking others in any form (mocking, insulting, disparaging, discriminating against religion, gender, ethnicity, causing mutual hatred, etc.)`}
                        <div style={{marginBottom: 10}}></div>
                        {`7. Advertising, propagating, buying and selling prohibited goods and services. Disseminating journalistic, literary, artistic works and publications that are prohibited;`}
                        <div style={{marginBottom: 10}}></div>
                        {`8. Forging organizations and individuals and spreading fake information, untruthful information infringing upon the legitimate rights and interests of organizations and individuals.`}
                        <div style={{marginBottom: 10}}></div>
                        {`9. Illegally obstructing the provision and access of lawful information, the provision and use of lawful services on the Internet by organizations and individuals.`}
                        <div style={{marginBottom: 10}}></div>
                        {`10. Unauthorized use of passwords, cryptographic keys of organizations and individuals, private information, personal information and Internet resources.`}
                        <div style={{marginBottom: 10}}></div>
                        {`11. Creating unauthorized links to legitimate domain names of organizations and individuals. Create, install, distribute malware, computer viruses. Unauthorized intrusion, hijacking of information systems, creating attack tools on the Internet.`}
                        <div style={{marginBottom: 10}}></div>
                        {`12. Set an account with the name of famous people, the names of leaders of the Party and the State, names of individuals, criminal organizations, reactionaries, terrorists or accounts with unhealthy meanings, contrary to the law. custom.`}
                        <div style={{marginBottom: 10}}></div>
                        {`13. Copy, distribute, transmit, broadcast, display, sell, license, change, modify or use any part of the service or any content.`}
                        <div style={{marginBottom: 10}}></div>
                        {`14. Disrupt, disable, engage in fraudulently or otherwise interfere with any part of the service, including security-related features or features.`}
                        <div style={{marginBottom: 10}}></div>
                        {`15. Distributing, spreading or promoting any activities to interfere, destroy or infiltrate the data of products, services provided or server systems.`}
                    </div>
                    <div className="ServicePolicy_content_title">
                        6. Rights of MEGAMESS
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`Provide services to the community, except for services prohibited by law;`}
                        <div style={{marginBottom: 10}}></div>
                        {`If you provide any information that is not truthful, inaccurate, or if we have reason to suspect that the information is inaccurate, or if you violate any provision of this Agreement, we I reserve the right to temporarily suspend, terminate or delete your account without your consent and without any liability to you.`}
                        <div style={{marginBottom: 10}}></div>
                        {`Any violation by the user in the process of using MEGAMESS’s products and services, we have the right to take away all your rights to the use of products and services at MEGAMESS, as well as request authorities intervene if necessary.`}
                        <div style={{marginBottom: 10}}></div>
                        {`When detecting violations such as cheating (cheats), cracking (hacking) or stealing information (hacks) or other errors, we reserve the right to use the information you provide when registering for an account to transfer to competent authorities for settlement in accordance with law.`}
                        <div style={{marginBottom: 10}}></div>
                        {`MEGAMESS has the right to refuse to support and settle accounts with inaccurate and incomplete information and to accounts that violate its responsibility for account security.`}
                    </div>
                    <div className="ServicePolicy_content_title">
                        7. Responsibilities of MEGAMESS
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`Publicize the agreement to provide and use the service.`}
                        <div style={{marginBottom: 10}}></div>
                        {`Responsible for supporting service users in the process of using products and services at MEGAMESS.`}
                        <div style={{marginBottom: 10}}></div>
                        {`Receive and resolve user complaints in the process of using MEGAMESS’s products and services, however, we only support, receive and resolve for accounts that provide complete, honest and accurate information. corpse.`}
                        <div style={{marginBottom: 10}}></div>
                        {`It is your responsibility to keep your personal information private, commit not to sell or exchange this information with third parties, except as required by law or approved by you.`}
                        <div style={{marginBottom: 10}}></div>
                        {`Ensure your discretion in allowing your personal information to be provided to other organizations, businesses or individuals. However, in order to best protect your personal information, at MEGAMESSi always warn you about the risks that this information can be accessed by bad objects beyond our control and You warrant that you accept these risks when sharing information.`}
                        <div style={{marginBottom: 10}}></div>
                        {`Coordinate with competent state management agencies to remove or block information with content that violates the provisions of Article 5 of this agreement.`}
                        <div style={{marginBottom: 10}}></div>
                        {`Provide personal information and private information of users related to terrorist activities, crimes, violations of the law at the request of competent state management agencies;`}
                        <div style={{marginBottom: 10}}></div>
                        {`Report according to regulations and subject to inspection and examination by competent state management agencies.`}
                    </div>
                    <div className="ServicePolicy_content_title">
                        8. Rights of service users
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`You may access and use the Service as provided to you, provided you comply with this agreement and applicable law.`}
                        <div style={{marginBottom: 10}}></div>
                        {`You have the right to change, supplement and update personal information, account information, and password.`}
                        <div style={{marginBottom: 10}}></div>
                        {`Have the right to decide in allowing your personal information to be provided to other organizations, businesses or individuals.`}
                        <div style={{marginBottom: 10}}></div>
                        {`To be protected personal information, private information in accordance with the law when providing complete and accurate personal information as prescribed.`}
                    </div>
                    <div className="ServicePolicy_content_title">
                        9. Release date
                    </div>
                    <div className="ServicePolicy_content_text">
                        The agreement to provide and use services at MEGAMESS is valid from May 14,2024
                    </div>
                </div>
                <div className="ServicePolicy_content" id="VietnameseVersion">
                    <div className="ServicePolicy_title">
                        Điều khoản và quy định dịch vụ của MEGAMESS.VN
                    </div>
                    <div>
                        <i>{`[Phiên bản tiếng Việt]`}</i>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <i><a href="#EnglishVersion">{`[Go English version]`}</a></i>
                    </div>
                    <div className="ServicePolicy_content_title">
                        Chào mừng đến với MEGAMESS
                    </div>
                    <div className="ServicePolicy_content_text">
                        {`Chào mừng khách hàng đến với trang web của chúng tôi.Cảm ơn bạn đã sử dụng sản phẩm và dịch vụ của chúng tôi (“Ứng dụng”). Bằng cách truy cập vào trang web và sử dụng Ứng dụng của chúng tôi, bạn đồng ý với các điều khoản dịch vụ do Công ty TNHH MEGAST SOFTWARE cung cấp. Vui lòng kiểm tra lại thường xuyên để biết các thay đổi của chúng tôi và đọc kỹ các điều khoản bên dưới:`}
                    </div>
                    <div className="ServicePolicy_content_title_big">
                        Nội dung:
                    </div>
                    <div className="ServicePolicy_content_policy">
                        <div className="ServicePolicy_content_title">
                            1. Sử dụng ứng dụng của chúng tôi
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Bạn chỉ có thể sử dụng ứng dụng của chúng tôi nếu bạn có thể hình thành hợp đồng ràng buộc một cách hợp pháp với Công ty TNHH MEGAST SOFTWARE và tuân thủ các điều khoản và tất cả các luật hiện hành. Khi tạo một tài khoản MEGAMESS, bạn phải cung cấp chính xác và đầy đủ thông tin. Không sử dụng trái phép Ứng dụng của chúng tôi. Chúng tôi có thể tạm ngừng hoặc ngừng cung cấp Ứng dụng nếu bạn không tuân thủ điều khoản hoặc chính sách. Việc bạn sử dụng Ứng dụng không có nghĩa là bạn sở hữu bất cứ quyền sở hữu trí tuệ nào đối với Ứng dụng của chúng tôi hoặc nội dung bạn truy cập. Không được xóa, không được che khuất hoặc thay đổi bất kỳ thông báo pháp lý nào được hiển thị trong hoặc cùng với Ứng dụng của chúng tôi. Chúng tôi có thể gửi cho bạn thông báo dịch vụ, thông báo quản trị và thông tin khác liên quan đến việc bạn sử dụng Ứng dụng.`}
                        </div>
                        <div className="ServicePolicy_content_title">
                            2. Tài khoản của bạn
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Công ty TNHH MEGAST SOFTWARE cho phép bạn đăng nội dung lên MEGAMESS, bao gồm hình ảnh, nhận xét, liên kết và các tài liệu khác. Bạn giữ quyền sở hữu bất kỳ quyền sở hữu trí tuệ nào mà bạn có trong nội dung đó. Bất kỳ nội dung nào bạn đăng hoặc xuất bản trên MEGAMESS đều được gọi là “Nội dung người dùng”. Bạn bảo lưu mọi quyền và hoàn toàn chịu trách nhiệm về Nội dung Người dùng mà bạn đăng trên MEGAMESS. Khách hàng có thể tham khảo các phương thức thanh toán trong điều khoản này và chọn áp dụng một cách phù hợp`}
                        </div>
                        <div className="ServicePolicy_content_title">
                            3. Điều kiện giao dịch chung
                        </div>
                        <div className="ServicePolicy_content_title_small">
                            a. Thanh toán chung:
                        </div>
                        <div className="ServicePolicy_content_text">
                            Các giá thành sản phẩm dịch vụ tại MEGAMESS đã bao gồm thuế VAT.
                            <div style={{marginBottom: 10}}></div>
                            {`Công ty TNHH MEGAST SOFTWARE có quyền cập nhật và thay đổi các giá gói dịch vụ tùy từng thời điểm. Việc thay đổi mức giá sẽ có hiệu lực vào thời điểm bạn bắt đầu đăng ký sử dụng dịch vụ tiếp theo sau ngày thay đổi mức phí. Khi tiếp tục trải nghiệm dịch vụ và thực hiện giao dịch, bạn đã đồng ý chấp nhận mức giá mới sau khi mức giá này có hiệu lực`}
                        </div>
                        <div className="ServicePolicy_content_title_small">
                            b. Quy trình cung cấp dịch vụ
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`MEGAMESS công khai các gói dịch vụ và biểu phí tương ứng của từng gói ngay trên website https://megamess.vn/. Người dùng thực hiện lựa chọn gói dịch vụ, phương thức thanh toán phù hợp và xác nhận tạo đơn hàng. Chúng tôi sẽ xét duyệt đơn hàng và xác nhận cung cấp dịch vụ ngay sau khi nhận được khoản thanh toán của bạn.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Trường hợp đơn hàng được thanh toán trong giờ hành chính (từ 8h sáng thứ 2 – 12h trưa thứ 7): đơn hàng được duyệt trong tối đa 1 tiếng kể từ khi MEGAMESS nhận được khoản thanh toán của bạn.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Trường hợp đơn hàng được thanh toán ngoài giờ hành chính sẽ được duyệt trong vòng 4 – 8 tiếng kể từ khi MEGAMESS nhận được khoản thanh toán.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Sau khi bạn hoàn tất đặt hàng, MEGAMESS sẽ gửi email thông tin chi tiết của đơn hàng đến bạn. Nếu bạn muốn thay đổi bất kỳ thông tin nào trong đơn hàng (gói dịch vụ, thông tin xuất hóa đơn,…), vui lòng liên hệ với chúng tôi qua hotline 0916961415 để được hỗ trợ.`}
                        </div>
                        <div className="ServicePolicy_content_title_small">
                            c. Cơ chế thanh toán 
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Nhằm mang đến cho khách hàng sự hài lòng khi trải nghiệm mua sắm và linh hoạt trong thanh toán, MEGAMESS.VN cung cấp các hình thức thanh toán sau:`}
                            <div style={{marginBottom: 10}}></div>
                            {`1. Thanh toán qua thẻ ATM nội địa.`}
                            <div style={{marginBottom: 10}}></div>
                            {`2. Thanh toán qua thẻ tín dụng, thẻ ghi nợ.`}
                            <div style={{marginBottom: 10}}></div>
                            {`3. Thanh toán chuyển khoản ngân hàng.`}
                            <div style={{marginBottom: 10}}></div>
                            {`4. Tiền mặt`}
                            <div style={{marginBottom: 10}}></div>
                            {`Đối với trường hợp phát sinh lỗi khi thanh toán, bạn có nghĩa vụ thông báo và cung cấp các chứng từ xác minh cho giao dịch thanh toán cho Công ty TNHH MEGAST SOFTWARE để kiểm tra và xử lý. Cụ thể là các trường hợp lỗi phát sinh từ phía ngân hàng hoặc thẻ của bạn`}
                        </div>
                        <div className="ServicePolicy_content_title_small">
                            d. Chính sách hoàn trả 
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Chúng tôi luôn cố gắng hỗ trợ bạn kinh doanh tốt hơn bằng những giải pháp phần mềm được nâng cấp liên tục. Nếu có bất kỳ điều gì không hài lòng và không muốn tiếp tục sử dụng, chúng tôi cam kết sẽ hoàn trả lại chi phí cho bạn trong tháng đó.`}
                        </div>
                        <div className="ServicePolicy_content_title_small">
                            e. Phạm vi và mục đích thu thập thông tin cá nhân
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Chúng tôi sẽ thu thập nhiều thông tin khác nhau khi bạn muốn mua sản phẩm trên MEGAMESS.VN. Chúng tôi thu thập, lưu trữ và xử lý thông tin của bạn cho quá trình mua hàng và cho những thông báo sau này và để cung cấp dịch vụ. Không giới hạn thông tin cá nhân: tên, giới tính, ngày sinh, email, địa chỉ, số điện thoại, chi tiết tài khoản ngân hàng. Bạn chỉ đưa cho chúng tôi những thông tin chính xác, không gây nhầm lẫn và phải thông báo cho chúng tôi biết nếu có thay đổi.`}
                        </div>
                        <div className="ServicePolicy_content_title_small">
                            f. Phạm vi sử dụng thông tin
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Thông tin của bạn chỉ được sử dụng trong các hoạt động quản lý và kinh doanh của MEGAMESS cũng như cung cấp cho bên liên kết. Chúng tôi chỉ chia sẻ thông tin thu thập được trong trường hợp cần thiết và quy định pháp luật cho các cơ quan, tổ chức, cá nhân sau:`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Đơn vị trung gian thanh toán: ngân hàng mà bạn đang sử dụng, trong trường hợp phát sinh lỗi khi thanh toán.`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Bên thứ ba có liên kết với MEGAMESS. Những bên thứ ba ngày chỉ có quyền truy cập vào dữ liệu cá nhân của bạn để thực hiện các nhiệm vụ này thay mặt chúng tôi và có nghĩa vụ không tiết lộ hoặc sử dụng nó cho bất kỳ mục đích nào khác.`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Cơ quan nhà nước có thẩm quyền.`}
                        </div>
                        <div className="ServicePolicy_content_title">
                            4. Quy định về giao dịch của người dùng với bên thứ ba
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`MEGAMESS hoàn toàn không chịu trách nhiệm rủi ro về mọi giao dịch của bạn với bên thứ ba trong quá trình sử dụng sản phẩm, dịch vụ tại MEGAMESS. Khi bạn sử dụng sản phẩm hoặc giao dịch với bên thứ ba, bạn đã hiểu và đồng ý tự chịu trách nhiệm những rủi ro phát sinh.`}
                        </div>
                        <div className="ServicePolicy_content_title">
                            5. Hành vi bị nghiêm cấm
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Những hành vi không tuân thủ quy định luật pháp Việt Nam. Thành viên không được lợi dụng việc cung cấp, sử dụng dịch vụ của MEGAMESS để thực hiện các việc sau`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Chống phá Nhà nước Cộng hòa xã hội chủ nghĩa Việt Nam, gây hại đến an ninh quốc gia, gây mất trật tự an toàn xã hội, phá hoại khối đại đoàn kết dân tộc, tuyên truyền chiến tranh, khủng bố, gây hận thù, mâu thuẫn giữa các dân tộc, sắc tộc, tôn giáo;`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Tuyên truyền, kích động bạo lực, dâm ô, đồi trụy, tội ác, tệ nạn xã hội, mê tín dị đoan, phá hoại thuần phong, mỹ tục của dân tộc;`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Tiết lộ bí mật nhà nước, bí mật quân sự, an ninh, kinh tế, đối ngoại và những bí mật khác do pháp luật quy định`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Đưa thông tin xuyên tạc, vu khống, xúc phạm uy tín của tổ chức, danh dự và nhân phẩm của cá nhân;`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Xúc phạm, nhạo báng người khác dưới bất kỳ hình thức nào (nhạo báng, lăng mạ, chê bai, kỳ thị tôn giáo, giới tính, sắc tộc, gây hiềm khích lẫn nhau….)`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Quảng cáo, tuyên truyền, mua bán hàng hóa, dịch vụ bị cấm. Truyền bá tác phẩm báo chí, văn học, nghệ thuật, xuất bản phẩm bị cấm;`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Giả mạo tổ chức, cá nhân và phát tán thông tin giả mạo, thông tin sai sự thật xâm hại đến quyền và lợi ích hợp pháp của tổ chức, cá nhân.`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Cản trở trái pháp luật việc cung cấp và truy cập thông tin hợp pháp, việc cung cấp và sử dụng các dịch vụ hợp pháp trên Internet của tổ chức, cá nhân.`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Sử dụng trái phép mật khẩu, khóa mật mã của tổ chức, cá nhân, thông tin riêng, thông tin cá nhân và tài nguyên Internet.`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Tạo đường dẫn trái phép đối với tên miền hợp pháp của tổ chức, cá nhân. Tạo, cài đặt, phát tán phần mềm độc hại, vi-rút máy tính. Xâm nhập trái phép, chiếm quyền điều khiển hệ thống thông tin, tạo lập công cụ tấn công trên Internet.`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Đặt tài khoản theo tên của danh nhân, tên các vị lãnh đạo của Đảng và Nhà nước, tên của cá nhân, tổ chức tội phạm, phản động, khủng bố hoặc tài khoản có ý nghĩa không lành mạnh, trái với thuần phong mỹ tục`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Sao chép, phân phối, truyền, phát, hiển thị, bán, cấp phép, thay đổi, sửa đổi hoặc sử dụng bất kỳ phần nào của dịch vụ hoặc bất kỳ nội dung nào`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Phá vỡ, vô hiệu hóa, lừa đảo tham gia hoặc can thiệp vào bất kỳ phần nào của dịch vụ, bao gồm các tính năng hoặc tính năng liên quan đến bảo mật.`}
                            <div style={{marginBottom: 10}}></div>
                            {`– Phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của sản phẩm, dịch vụ cung cấp hoặc hệ thống máy chủ.`}
                        </div>
                        <div className="ServicePolicy_content_title">
                            6. Quyền của MEGAMESS
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Cung cấp dịch vụ cho cộng đồng trừ các dịch vụ bị cấm theo quy định của pháp luật;`}
                            <div style={{marginBottom: 10}}></div>
                            {`Nếu bạn cung cấp bất kỳ thông tin nào không trung thực, không chính xác, hoặc nếu chúng tôi có cơ sở để nghi ngờ rằng thông tin đó không chính xác hoặc nếu bạn vi phạm bất cứ điều khoản nào trong Thỏa thuận này, chúng tôi có toàn quyền tạm khóa, chấm dứt, xóa bỏ tài khoản của bạn mà không cần sự đồng ý của bạn và không phải chịu bất cứ trách nhiệm nào đối với bạn.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Mọi vi phạm của người sử dụng trong quá trình sử dụng sản phẩm, dịch vụ của MEGAMESS, chúng tôi có quyền tước bỏ mọi quyền lợi của bạn  đối với việc sử dụng các sản phẩm, dịch vụ tại MEGAMESS, cũng như sẽ yêu cầu cơ quan chức năng can thiệp nếu cần thiết.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Khi phát hiện những vi phạm như gian lận (cheats), bẻ khóa (crack) hay đánh cắp thông tin (hacks) hoặc những lỗi khác, chúng tôi có quyền sử dụng những thông tin mà bạn cung cấp khi đăng ký tài khoản để chuyển cho Cơ quan chức năng giải quyết theo quy định của pháp luật.`}
                            <div style={{marginBottom: 10}}></div>
                            {`MEGAMESS có quyền từ chối hỗ trợ, giải quyết đối với tài khoản đăng ký thông tin không chính xác, đầy đủ và đối với những tài khoản vi phạm trách nhiệm bảo mật tài khoản. `}
                        </div>
                        <div className="ServicePolicy_content_title">
                            7. Trách nhiệm của MEGAMESS
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Công khai thỏa thuận cung cấp và sử dụng dịch vụ.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Có trách nhiệm hỗ trợ người sử dụng dịch vụ trong quá trình sử dụng sản phẩm, dịch vụ tại MEGAMESS.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Nhận và giải quyết khiếu nại của người sử dụng trong quá trình sử dụng sản phẩm, dịch vụ của MEGAMESS, tuy nhiên chúng tôi chỉ hỗ trợ, nhận và giải quyết đối với tài khoản cung cấp đầy đủ thông tin, trung thực và chính xác.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Có trách nhiệm bảo mật thông tin cá nhân của bạn, cam kết không bán hoặc trao đổi những thông tin này với bên thứ ba, trừ trường hợp theo quy định pháp luật hoặc được bạn chấp nhận`}
                            <div style={{marginBottom: 10}}></div>
                            {`Bảo đảm quyền quyết định của bạn trong việc cho phép thông tin cá nhân của mình được cung cấp cho tổ chức, doanh nghiệp, cá nhân khác. Tuy nhiên, để bảo vệ tốt nhất thông tin cá nhân của bạn, tại MEGAMESS luôn cảnh báo bạn về các nguy cơ về việc các thông tin này có thể bị tiếp cận bởi các đối tượng xấu nằm ngoài sự kiểm soát của chúng tôi và bạn chắc chắn rằng mình chấp nhận các rủi ro này khi chia sẻ thông tin`}
                            <div style={{marginBottom: 10}}></div>
                            {`Phối hợp với cơ quan quản lý nhà nước có thẩm quyền để loại bỏ hoặc ngăn chặn thông tin có nội dung vi phạm quy định tại Điều 5 của thỏa thuận này.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Cung cấp thông tin cá nhân và thông tin riêng của người sử dụng có liên quan đến hoạt động khủng bố, tội phạm, vi phạm pháp luật khi có yêu cầu của cơ quan quản lý nhà nước có thẩm quyền;`}
                            <div style={{marginBottom: 10}}></div>
                            {`Báo cáo theo quy định và chịu sự thanh tra, kiểm tra của các cơ quan quản lý nhà nước có thẩm quyền`}
                        </div>
                        <div className="ServicePolicy_content_title">
                            8. Quyền của người sử dụng dịch vụ
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Bạn có thể truy cập và sử dụng dịch vụ như được cung cấp cho bạn, miễn là bạn tuân thủ thỏa thuận này và luật hiện hành. `}
                            <div style={{marginBottom: 10}}></div>
                            {`Bạn được quyền thay đổi, bổ sung, cập nhật thông tin cá nhân, thông tin tài khoản, mật khẩu`}
                            <div style={{marginBottom: 10}}></div>
                            {`Có quyền quyết định trong việc cho phép thông tin cá nhân của mình được cung cấp cho tổ chức, doanh nghiệp, cá nhân khác.`}
                            <div style={{marginBottom: 10}}></div>
                            {`Được bảo vệ thông tin cá nhân, thông tin riêng theo quy định pháp luật khi đã cung cấp đầy đủ, chính xác thông tin cá nhân theo quy định.`}
                        </div>
                        <div className="ServicePolicy_content_title">
                            9. Thời điểm ban hành
                        </div>
                        <div className="ServicePolicy_content_text">
                            {`Thỏa thuận cung cấp và sử dụng dịch vụ tại MEGAMESS có giá trị từ ngày 14 tháng 5 năm 2024`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicePolicy;