import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const apiPackage = axios.create({
    baseURL: apiUrl,
});

export const postPackage = async (token, data) => {
    try {
        const response = await apiPackage.post(`/api/user/transaction`, data, {
            headers: {
                'Content-Type': 'application/json', // Example header
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}