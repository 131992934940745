import React, { useState, useRef, useEffect } from "react";
import './style.scss';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AdminUsers = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dataUser = props.user;
  const [openMenuInfor, setOpenMenuInfor] = useState(false);
  const modalRef = useRef(null);
  const btnModalOpen = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnModalOpen.current && btnModalOpen.current.contains(event.target)) {
        setOpenMenuInfor(!openMenuInfor);
      } else if (openMenuInfor && modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenMenuInfor(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuInfor, modalRef]);

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('mfmToken');
    localStorage.removeItem('mfmUserID');
    navigate('/login');
    window.FB.getLoginStatus(function (response) {
      if (response && response.status === "connected") {
        window.FB.logout(function (response) {
          navigate('/login');
        });
      } else if (response.status === "unknown") {
        window.FB.logout(function (response) {
          navigate('/login');
        });
      }
    });
  }

  return (
    <div className="usersAdmin">
      <MenuIcon />
      <div className="usersAdmin_content">
        <div className="usersAdmin_imformation" ref={btnModalOpen}>
          <div className="usersAdmin_imformation_img">
            <img className="usersAdmin_imformation_avatar" width={50} src={dataUser?.avatar} alt="avatar" />
            <div className="usersAdmin_imformation_btn">
              <ExpandMoreIcon sx={{ width: '18px', height: '15px', color: 'white' }} />
            </div>
          </div>
          <div className="usersAdmin_imformation_acount">
            Tài khoản
          </div>
        </div>
        {openMenuInfor && <div className="usersAdmin_menu" ref={modalRef}>
          <div className="usersAdmin_menu_item">
            <img className="usersAdmin_menu_item_avatar" width={40} src={dataUser?.avatar} alt="avatar" />
            <div className="usersAdmin_menu_item_name">
              {dataUser?.name}
            </div>
          </div>
          <div className="usersAdmin_menu_item_line"></div>
          <div className="usersAdmin_menu_item">
            <SettingsIcon/>
            <div className="usersAdmin_menu_text">
              {t('UserAdmin.setting_account')}
            </div>
          </div>
          <div className="usersAdmin_menu_item" onClick={logout}>
            <LogoutOutlinedIcon/>
            <div className="usersAdmin_menu_text">
              {t('UserAdmin.logout')}
            </div>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default AdminUsers