// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './language/i18n';
import Home from './pages/Home';
import LoginForm from './pages/LoginForm';
import ServicePolicy from './pages/ServicePolicy/ServicePolicy';
import PrivacyTerms from './pages/PrivacyTerms/PrivacyTerms';

const App = () => {
  const token = localStorage.getItem('accessToken');
  const userID = localStorage.getItem('fbUserID');
  const isLoggedIn = token && userID;

  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/Information" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/*" element={<Home />} />
          <Route path="/ServicePolicy" element={<ServicePolicy />} />
          <Route path="/PrivacyTerms" element={<PrivacyTerms />} />
        </Routes>
      </I18nextProvider>
    </Router>
  );
};

export default App;

