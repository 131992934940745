import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCjd7cYv1G-eHCx8VgeB1XGvZT7uSFgVQQ",
    authDomain: "verify-megamess.firebaseapp.com",
    projectId: "verify-megamess",
    storageBucket: "verify-megamess.appspot.com",
    messagingSenderId: "1026833150204",
    appId: "1:1026833150204:web:f2e43391cb3456c195a779",
    measurementId: "G-M8S68F9BL4"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default firebase;