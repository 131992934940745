export function initFacebookSdk() {
  return new Promise(resolve => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: '1407208256848713',
        cookie: true,
        xfbml: true,
        version: "v19.0"
      });

      window.FB.AppEvents.logPageView();
      
      resolve();
    };

    (function(d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}
